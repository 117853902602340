import React, { useState, useEffect } from 'react';
import '../New_lk.css';
import axios from "axios";

const tg = window.Telegram.WebApp;

function Settings() {
    tg.setHeaderColor('#1A659E')
    const [disable, setDisable] = useState(false); // Счётчик нажатий

    /////////////////////////////
    // Отключение масштабирования
    /////////////////////////////

    useEffect(() => {
        const preventZoom = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', preventZoom, { passive: false });
        return () => {
            window.removeEventListener('wheel', preventZoom);
        };
    }, []);
    /////////////////////////////
    /////////////////////////////

    const BackButton = tg.BackButton;
    BackButton.show();
    BackButton.onClick(function() {
        BackButton.hide();
        tg.HapticFeedback.impactOccurred('rigid');
    });
    tg.onEvent('backButtonClicked', function() {
        window.location.href = '/new_lk';
    });

    const handleSupportClick = () => {
        // Вызов хаптика успеха
        tg.HapticFeedback.notificationOccurred('success');
        // Открытие чата с поддержкой
        tg.openTelegramLink('https://t.me/click_vpn_help');
    };

    const handleDisableAutoPayment = () => {
        tg.HapticFeedback.notificationOccurred('error');

        if (disable === false) {

            tg.showConfirm('Вы уверены, что хотите отключить подписку?', (confirmation) => {
                if (confirmation) {
                    const data = {
                        userId: tg.initDataUnsafe?.user?.id,
                    };
                    axios.post(`https://click-vpn.ru:8443/API/delete_autopay`, data)
                        .then(() => {
                            tg.showAlert(`Подписка отключена.`);
                            setDisable(true)
                        })
                        .catch(error => {
                            console.error('Error disabling subscription:', error);
                            tg.showAlert('Произошла ошибка при отключении подписки. Пожалуйста, попробуйте снова.');
                        });
                }
            });

        } else {

            tg.showAlert('Подписка уже отключена.');

        }

    };

    const handleGetInstructions = () => {
        // Вызов хаптика для успеха
        tg.HapticFeedback.notificationOccurred('success');

        // Подготовка данных для отправки на сервер
        const data = {
            userId: tg.initDataUnsafe?.user?.id,
            passwd: '3569'  // Здесь нужно указать ваш пароль для проверки
        };

        // Отправка POST-запроса на сервер
        axios.post('https://click-vpn.ru:8443/API/instructions', data)
            .then(response => {
                console.log('Инструкции отправлены:', response.data);
                tg.close()
            })
            .catch(error => {
                console.error('Ошибка при отправке инструкций:', error);
                tg.showAlert('Ошибка при отправке инструкций. Попробуйте еще раз.');
            });
    };

    return (
        <div className="lk">
            <div className="new-subscription-card">
                <div className="new-subscription-title">
                    Настройки <div className="rotate-emoji">⚙️</div>
                </div>
                <p className="subscription-details">Инструкции по настройке VPN:</p>
                <button className="new-subscription-button-settings" onClick={handleGetInstructions}>
                    <div>Получить инструкции</div>
                    <div>📋</div>
                </button>
                <p className="subscription-details">Помощь в решении проблем:</p>
                <button className="new-subscription-button-settings" onClick={handleSupportClick}>
                    <div>Связаться с поддержкой</div>
                    <div>🧑‍💻</div>
                </button>
                <p className="subscription-details">Вы можете отключить автосписания:</p>
                <button className="new-subscription-button-settings"  onClick={handleDisableAutoPayment}>
                    <div>Отключить подписку</div>
                    <div>❌</div>
                </button>
            </div>
        </div>
    );
}

export default Settings;
